<template>
    <div class="row main-nav" id="main-nav">
      <nav class="navbar navbar-expand-lg navbar-light left-menu">
        <span v-if="isInPrivacyPage && noLogo" class="h6 fw-bolder mb-0 py-0 py-md-3">Privacy and Terms</span>
        <template v-if="!noLogo">
          <a class="navbar-brand" id="header-icon" href="/" name="home">
            <span class="sr-only">WhiteStrings</span>
            <img fetchpriority="high" src="@/assets/graphics/v2/ws_logo.webp" alt="" srcset="">
          </a>
          <span v-if="isInPrivacyPage" class="h6 fw-bolder mb-0 d-none d-md-inline">Privacy and Terms</span>
        </template>
        <button class="navbar-toggler" type="button" aria-label="Toggle navigation" @click="showMenu(true)">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav" v-if="!isInPrivacyPage">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0 -font-nunitoSans-light">
            <li class="nav-item ">
              <router-link @click.native="scrollToTop" to="/" class="nav-link" exact>Home</router-link>
            </li>
            <li class="nav-item ">
              <router-link @click.native="scrollToTop" to="/features" class="nav-link" exact>Features</router-link>
            </li>
            <li class="nav-item ">
              <router-link @click.native="scrollToTop" to="/mobile" class="nav-link" exact>Mobile App</router-link>
            </li>
            <li class="nav-item ">
              <router-link @click.native="scrollToTop" to="/about" class="nav-link" exact>About Us</router-link>
            </li>
            <li class="nav-item ">
              <router-link @click.native="scrollToTop" to="/book" class="nav-link" exact>Contact Us</router-link>
            </li>
          </ul>
        </div>
        <div id="searchResultContainer" class="ms-auto col-4 position-relative d-none d-md-block" v-else-if="privacyActiveTab != 'overview'">
          <div class="position-relative">
            <span class="search-icon-container"><SearchIcon /></span>
            <input type="text" class="form-control search-input" placeholder="Search..." v-model="searchKeyword" @input="search">
          </div>
          <ul id="searchResultList" :class="'dropdown-menu search-result ' + (searchKeyword ? 'show' : '')" data-popper-placement="bottom-start">
            <li v-if="searchLoading" class="no-result">Searching...</li>
            <template v-else-if="searchResults && searchResults.length > 0">
              <li v-for="(item, i) in searchResults" :key="i"><a class="dropdown-item" @click="moveToDiv(item.id)">{{ item.title }}</a></li>
            </template>
            <li v-else class="no-result">No results found.</li>
          </ul>
        </div>
      </nav>
  
      <div id="myNav" class="overlay" v-if="mobileMenu">
        <a class="closebtn cursor-pointer" @click="showMenu(false)" >&times;</a>
        <div class="overlay-content">
          <a href="/" aria-label="Visit our homepage" name="Home" role="button" aria-describedby="Home">Home</a>
          <a href="/features" aria-label="Explore Our Product Features" name="Features"  role="button"  aria-describedby="Features">Features</a>
          <a href="/mobile" aria-label="Download Our Mobile App" name="Mobile App"  role="button" aria-describedby="Mobile App">Mobile App</a>
          <a href="/about" aria-label="Learn About Our Company" name="About Us" aria-describedby="About Us">About Us</a>
          <a href="/book" aria-label="Get in Touch with Us" name="Contact Us" aria-describedby="Contact Us">Contact Us</a>
        </div>
      </div>
    </div>
  </template>
  
<script>
import SearchIcon from '@/components/svgs/icons/Search'
// import wsLogo from '@/components/svgs/wsLogo'

export default {
  name: 'WsHeader',
  props: {
    isInPrivacyPage: {
      type: Boolean,
      default: false
    },
    noLogo: {
      type: Boolean,
      default: false
    }
  },
  components: {
    // wsLogo,
    SearchIcon
  },
  data() {
    return {
      mobileMenu: false,
      keyword: null,
      searchLoading: false
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    privacyActiveTab () {
      return this.$store.state.privacy.activeTab
    },
    searchKeyword: {
      get () {
        return this.$store.state.searchKeyword
      },
      set (val) {
        this.$store.commit('setSearchKeyword', val)

        return this.$store.state.searchKeyword
      }
    },
    searchResults () {
      return this.$store.state.searchResults
    }
  },
  methods: {
    handleScroll () {
      if(window.scrollY > 60){
          document.getElementById("main-nav").classList.add("nav-fixed");
      }else{
          document.getElementById("main-nav").classList.remove("nav-fixed");
      }
    },
    showMenu(setStatus) {
      this.mobileMenu = setStatus;

      if (setStatus) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    },
    scrollToTop() {
      window.scrollTo(0,0);
    },
    activeTabClass(value) {
      return this.$router.currentRoute.path == value ? ' active' : ''
    },
    search () {
      this.searchLoading = true
      const activeTab = this.$store.state.privacy.activeTab
      const privacyKeyPoints = this.$store.state.privacy.keyPoints
      const privacyData = this.$store.state.privacy.tableOfContentsDetails
      const termsData = this.$store.state.terms
      let keyword = this.$store.state.searchKeyword
      const vm = this

      if (keyword) {
        keyword = keyword.toLowerCase()
        
        this.$func.delay(function () {
          let results = null

          if (activeTab == 'privacy') {
            const result1 = vm.filterResults(vm, privacyKeyPoints)
            const result2 = vm.filterResults(vm, privacyData)

            results = result1.concat(result2)
          } else if (activeTab == 'terms') {
            results = vm.filterResults(vm, termsData.list)
          }

          vm.$store.commit('setSearchResults', results)

          vm.searchLoading = false
        }, 500)
      }
    },
    moveToDiv (id) {
      this.$func.moveToDiv(id)
    },
    filterResults (vm, data) {
      return data.filter(function (item) {
        const title = item.title.replace(/([0-9])+./, '').trim()
      
        if ((title.toLowerCase()).indexOf(vm.$store.state.searchKeyword) >= 0) {
          return true
        } else {
          return false
        }
      })
    }
  },
  mounted() {
    const vm = this
    window.addEventListener('click', function(e){   
      if (document.getElementById('searchResultContainer') && !document.getElementById('searchResultContainer').contains(e.target)){
        vm.$store.commit('setSearchResults', [])
        vm.$store.commit('setSearchKeyword', null)
      }
    });
  },
}
</script>