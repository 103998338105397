import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
// import Features from '../views/Features.vue'
// import Book from '../views/Book.vue'



import Home from '../views/v2/Home.vue'
import Features from '../views/v2/Features.vue'
import Mobile from '../views/v2/Mobile.vue'
import AboutUs from '../views/v2/AboutUs.vue'
import BookADemo from '../views/v2/BookADemo.vue'
// import PrivacyAndTerms from '../views/v2/PrivacyAndTerms.vue'
// import PageNotFound from '../views/v2/PageNotFound.vue'

Vue.use(VueRouter)

function lazyLoad(view){
  return() => import(`../views/v2/${view}.vue`)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/features',
    name: 'Features',
    component: Features
  },
  {
    path: '/mobile',
    name: 'Mobile',
    component: Mobile
  },
  {
    path: '/about',
    name: 'About Us',
    component: AboutUs
  },
  {
    path: '/book',
    name: 'Book a Demo',
    component: BookADemo
  },
  {
    path: '/privacy-and-terms',
    name: 'Terms and Condition',
    component: lazyLoad('PrivacyAndTerms')
  },
  {
    path: '/m/privacy-and-terms',
    name: 'Terms and Condition',
    component: lazyLoad('PrivacyAndTerms')
  },
  {
    path: '/medstaff-privacy',
    name: 'Terms and Condition',
    component: lazyLoad('PrivacyAndTerms')
  },
  { 
    path: '/:pathMatch(.*)*', 
    name: 'Page Not Found',
    component: lazyLoad('PageNotFound') 
  },
  /**
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/features',
    name: 'features',
    component: Features
  },
  {
    path: '/book',
    name: 'book',
    component: Book
  },
  */
  // {
    // path: '/features',
    // name: 'features',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0, behavior: 'instant' }
  }
})

export default router
