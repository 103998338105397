<template>
  <div id="app" :class="appClass">
    <WsHeader :isInPrivacyPage="isInPrivacyPage" :noLogo="noLogo" v-if="showHeader"/>
      <router-view/>
    <WsFooter v-if="showFooter" />
  </div>
</template>

<script>
// @ is an alias to /src
import WsHeader from '@/components/v2/WsHeader.vue'
import WsFooter from '@/components/v2/WsFooter.vue'

export default {
  name: 'MainApp',
  components: {
    WsHeader,
    WsFooter,
  },
  data () {
    return {
      showFooter: true,
      showHeader: true,
      isInPrivacyPage: false,
      appClass: '',
      noLogo: false
    }
  },
  watch:{
    $route (to){
      this.initPage(to.path)
    }
  },
  mounted () {
    this.initPage(this.$route.path)
  },
  methods: {
    initPage (path) {
      this.showHideFooter(path)
      this.showHideHeader(path)
      
      if (path == '/m/privacy-and-terms') {
        this.appClass = 'no-header-footer'
      }

      if (path == '/medstaff-privacy') {
        this.noLogo = true
      }
    },
    showHideFooter (path) {
      if (path == '/privacy-and-terms' || path == '/m/privacy-and-terms' || path == '/medstaff-privacy') {
        this.showFooter = false
        this.isInPrivacyPage = true
      } else {
        this.showFooter = true
        this.isInPrivacyPage = false
      }
    },
    showHideHeader (path) {
      if (path == '/m/privacy-and-terms') {
        this.showHeader = false
      } else {
        this.showHeader = true
      }
    },
  }
}
</script>

<style>
@import './assets/styles/main.css';
</style>